<template>
    <div class="my-learning-box">
        <div class="tab">
            <span v-for="item in tabList" :key="item.id" :class="{active : tabValue === item.key}" @click="tabClick(item)">
                {{$t(item.value)}}
            </span>
        </div>
        <trainingTask v-if="tabValue === 'trainingTask'" :validity="validity ? validity.value : ''"></trainingTask>
        <trainingCamp v-if="tabValue === 'trainingCamp'" :validity="validity ? validity.value : ''"></trainingCamp>
        <evaluation-list v-if="tabValue === 'evaluation'" :validity="validity ? validity.value : ''"></evaluation-list>
        <exam-list v-if="tabValue === 'exam'"></exam-list>
        <live-list v-if="tabValue === 'live'"></live-list>
        <practiceList v-if="tabValue === 'test'"></practiceList>
    </div>
</template>
<script>
import ExamList from '../../../layout/business/examList'
import LiveList from '../../../layout/business/liveList'
import EvaluationList from '../../../layout/business/evaluationList'
import practiceList from '../../practice/page/practiceList.vue'
export default {
    name:'myLearning',
    components:{
        practiceList,
        ExamList,
        EvaluationList,
        LiveList
    },
    data(){
        return{
            tabValue:'',
            tabList:[
                // {
                //     id:1,
                //     key:'trainingTask',
                //     value:'培训任务'
                // },
                // {
                //     id:2,
                //     key:'trainingCamp',
                //     value:'训练营'
                // },
                // {
                //     id:3,
                //     key:'evaluation',
                //     value:'测评'
                // },
                // {
                //     id:4,
                //     key:'exam',
                //     value:'考试'
                // }
            ],
            validity:'',
        }
    },
    created(){
        
        const configurationArr = JSON.parse( localStorage.getItem('configurationArr') )
        // tab 数组
        const learningTab = configurationArr && configurationArr.filter(item => {
            if(item.key === 'task_tag_array_learn_center'){
                return true
            }
        })[0];
        // 是否显示训练营，任务，测评 有效期
        this.validity =  configurationArr && configurationArr.filter(item => {
            if(item.key === 'is_show_task_term_validity'){
                return true
            }
        })[0];
        // this.validity = validity || ''
        
        this.tabList = learningTab && JSON.parse(learningTab.value) || []
        // 如果是国际化隐藏掉训练营和测评
        if(localStorage.getItem('is_i18n') == 'true'){
            // this.tabList = this.tabList && this.tabList.filter(item => {
            //     console.log('item',item.key);
            //     if(item.key != 'trainingCamp' && item.key != 'evaluation' && item.key !='test'){
            //         return true
            //     }
            // })
            this.tabList = [{
                key:'trainingTask',
                value:'WDNPC_WEB_RAINING_TASK', //培训任务
                id:1
            },
            {
                key:'exam',
                value:'WDNPC_WEB_EXAM', //考试
                id:4
            },{
                key:'live',
                value:'WDNPC_WEB_LIVE_STREAMING', //直播
                id:5
            }]
        }
        this.tabValue = this.$route.query.backModule || (this.tabList.length && this.tabList[0].key)
    },
    methods:{
        tabClick(item){
            this.tabValue = item.key;
        },
    }
    
}
</script>
<style lang="stylus" scoped>
@import '../css/learning.styl'
</style>