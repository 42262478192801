<template>
  <div class="evaluate">
    <div class="top_back" @click="onClickLeft">
      <svg-icon icon-class="back"></svg-icon>
      <span>
        返回
      </span>
    </div>

    <div class="container">
      <div class="describe">
        <img :src="oGoods.picUrl" alt="" class="desc_img" />
        <p class="info" :title="oGoods.goodsName">{{ oGoods.goodsName }}</p>
      </div>
      <div class="evaluta_star">
        <star
          @change="onChange"
          :bDisable="bDisabled"
          :total="nTotal"
          :b-total="bTotal"
          :a-star="aStar"
        />
      </div>
      <div class="field">
        <el-input
          type="textarea"
          :disabled="bDisabled"
          placeholder="请输入评价内容，限制30字"
          v-model="message"
          :rows="2"
          maxlength="30"
          show-word-limit
        >
        </el-input>
      </div>
    </div>
    <div class="btn_wrap" v-if="!bTotal">
      <button class="btn_info" @click="publish">发布评论</button>
    </div>
  </div>
</template>

<script>
import star from "./star";

export default {
  name: "evaluate-page",
  data() {
    return {
      message: "",
      bTotal: false,
      nTotal: 0,
      bDisabled: false,
      itemList: [],
      // aStar: ["有点差", "一般", "还不错", "很满意", "非常满意"],
      aStar: [],
      aTexts: [],
      bLook: null,
      oGoods: {},
    };
  },
  props: {
    sLook: {
      type: Boolean,
      default: false,
    },
    orderNo: {
      type: String,
      default: "",
    },
  },
  created() {
    this.isRoute();
  },
  components: {
    star,
  },
  methods: {
    isRoute() {
      const look = this.sLook;
      const orderId = this.orderNo;
      if (look) {
        this._findByOrderNo({
          type: 1, //查看0 发布1
          orderNo: orderId,
        });
        this.aStar = [
          { evaluationType: 0, score: 0, evaluationTypeName: "培训课程" },
          { evaluationType: 1, score: 0, evaluationTypeName: "院校环境" },
          { evaluationType: 2, score: 0, evaluationTypeName: "师资规模" },
          { evaluationType: 3, score: 0, evaluationTypeName: "服务态度" },
        ];
      } else {
        // 查看
        this.bDisabled = true;
        this.bTotal = true;
        this._findByOrderNo({
          type: 0, //查看0 发布1
          orderNo: orderId,
        });
      }
    },
    _findByOrderNo(params) {
      this.$api.evaluate.findByOrderNo(params).then((res) => {
        let { itemList, goodsName, picUrl, content, aveScore } = res.data;
        this.oGoods = {
          goodsName,
          picUrl,
        };
        if (!this.sLook) {
          this.nTotal = aveScore;
          this.message = content;
          this.aStar = itemList;
        }
      });
    },
    _stuSave(data) {
      this.$api.evaluate.stuSave(data).then((res) => {
        let { data } = res;
        if (data) {
          this.$message({
            type: "success",
            message: "发布成功",
          });
          this.onClickLeft();
        }
      });
    },
    publish() {
      let itemList = this.itemList;
      if (itemList.length < 4) {
        this.$message({
          type: "warning",
          message: "四个维度星级请评分",
        });

        return;
      }
      this._stuSave({
        orderNo: this.orderNo,
        content: this.message.trim(),
        itemList,
      });
    },
    onClickLeft() {
      this.$emit("back");
    },
    onChange(item) {
      this.itemList.push(item);
      this.itemList = [...new Set(this.itemList)];
    },
  },
};
</script>

<style lang="less" scoped>
.evaluate {
  font-family: PingFangSC-Regular, PingFang SC;
  .top_back {
    display: flex;
    align-items: center;
    margin-top: 4px;
    cursor: pointer;
    .icon {
      font-size: 18px;
      margin-right: 4px;
    }
    span {
      color: #316fff;
      font-size: 18px;
      font-weight: bold;
    }
  }
  .container {
    position: relative;
    background: #fff;
    border-radius: 16px;
    .describe {
      display: flex;
      border: 1px solid #eaeaea;
      margin-top: 24px;
      padding: 24px;
      height: 150px;
      overflow: hidden;
      background: #fff;
      .desc_img {
        width: 242px;
        height: 150px;
        margin-right: 16px;
      }
      .info {
        padding-top: 20px;
        font-size: 20px;
        font-weight: 400;
        color: #333333;
        line-height: 48px;
      }
    }
    .field {
      width: 480px;
      margin-bottom: 56px;
      color: #000;
      /deep/ .el-textarea__inner {
        border-radius: 4px;
        border: 1px solid #d7dcee;
        font-size: 12px;
        font-weight: 400;
        color: #000;
        line-height: 17px;
        height: 120px;
        padding: 16px;
      }
    }
  }
  .btn_wrap {
    margin-top: 56px;
    text-align: center;
    .btn_info {
      width: 256px;
      height: 40px;
      background: #316fff;
      border-radius: 4px;
      font-size: 16px;
      font-weight: 400;
      color: #ffffff;
      border: none;
    }
  }
}
.ellipsis {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
