<!-- 
 * @description: 我的消息
 * @fileName: my-message.vue 
 * @author:ninghf 
 * @date: 2021-08-16 09:53:32
!-->
<template>
    <div class="my-message">
        <div class="tab-container">
            <div class="tab-header">
                <p class="header-txt" v-for="item in tabList" :key="item.id" :class="{active : activeValue == item.id}" @click="tabClick(item)">
                    {{item.value}}
                </p>
            </div>
            <div class="tab-content">
                <div class="tab-handle">
                    <div class="search-box">
                        <el-input v-model="keyword" :placeholder="$t('WDNPC_WEB_ENTER_A_KEYWORD',{text:'请输入关键字'})"></el-input>
                        <span class="search-icon" @click="getData(1)">
                            <svg-icon icon-class="search"></svg-icon>
                        </span>
                    </div>
                    <el-button @click="readAllMessage()" type="button" v-if="activeValue!=1 && list.length">{{$t('WDNPC_WEB_ALL_READED',{text:'全部已读'})}}</el-button>
                </div>
                <ul class="message-list">
                    <li v-for="item in list" :key="item.id" class="message-item" :class="[item.readStatus!=1?' unread':'',item.isOpen?' isOpen':'']">
                        <div class="message-item-top">
                            <div class="time-p">
                               <p>
                                    <svg-icon icon-class="inform"></svg-icon>
                                    <span class="message-time">{{item.sendTime}}</span>
                               </p>
                            </div>
                            <p  class="message-title">
                                {{item.msgTitle}}
                            </p>
                            <span @click="openMessage(item)" class="arrow-toggle">
                                <svg-icon icon-class="arrow-double-down"></svg-icon>
                            </span>
                        </div>
                        <div  class="message-item-bottom">
                            <p  class="message-content">{{item.msgContent}}</p>
                            <el-button type="button" @click="handleDelete(item.id)">{{$t('WDNPC_WEB_DELETE',{text:'删除'})}}</el-button>
                        </div>
                    </li>
                </ul>
            </div>
            <paging class="paging" :paging="paging"  v-if="list.length>0 || paging.total>0" />
           <div class="nodata-tip" v-if = "list.length == 0">
                <img src="../../../assets/images/common/nodata.png" alt="">
                <p>{{$t('WDNPC_WEB_NO_MESSAGE_DATA',{text:'暂无消息数据'})}}～</p>
            </div>
        </div>
        <el-dialog
            class="warning-dialog"
            :visible.sync="deletedialogVisible"
            width="400px"
            >
            <svg-icon icon-class="tips" class-name="icon-img"></svg-icon>
            <p class="titles">{{$t('WDNPC_WEB_PROMPT',{text:'提示'})}}</p>
            <p class="text">{{$t('WDNPC_WEB_WHETHER_TO_DELETE_THE_CURRENT_INFORMATION',{text:'是否删除当前信息？'})}}</p>
            <div slot="footer" class="dialog-footer">
                <el-button type="default" style="width:120px;" @click="deletedialogVisible = false"
                >{{$t('WDNPC_WEB_CANCEL',{text:'取消'})}}</el-button
                >
                <el-button type="primary" style="width:120px;background:#316FFF;border:none;" @click="handleDeleteConfirm"
                >{{$t('WDNPC_WEB_OK',{text:'确定'})}}</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>
<script>
import paging from "../../../layout/paging";
export default {
    name:'MyMessage',
    data(){
        return{
            list:[],
            deleteMessageId:'',
            activeValue:null,
            keyword:'',
            deletedialogVisible:false,
            isOpen:false,
            paging:{
                params:{
                    pageNum: 1,
                    pageSize: 10
                },
                total: 0,
                totalPages: 0,
                currentSize: 0,
            },
            tabList:[
                {
                    id:null,
                    value:'全部消息',//{text:'全部消息'})'
                },
                {
                    id:0,
                    value:'未读消息',//{text:'未读消息'})'
                },
                {
                    id:1,
                    value:'已读消息',//{text:'已读消息'})'
                },
            ],
        }
    },
    components:{
        paging
    },
    methods:{
        tabClick(item){
            this.activeValue = item.id;
            this.keyword = '';
            this.paging.params.pageNum = 1;
            this.paging.params.pageSize = 10;
            this.getData();
        },
        // 阅读消息
        openMessage(item){
            this.$set(item, 'isOpen', !item.isOpen)
            if(item.readStatus!=1){
                    const params = {
                        id:item.id
                    }
                    this.$api.message.readOne(params).then(res=>{
                    if(res.data){
                        item.readStatus = 1;
                        this.$emit('existUnread',true);
                    }
                })
            }
        },
        getData(type){
            if(type){
                this.paging.params.pageNum = 1;
            }
            let data ={
                keyword:this.keyword,
                readStatus:this.activeValue,//是否已读 1：是 0：否
            }
            let params = {
                pageNum:this.paging.params.pageNum,
                pageSize:this.paging.params.pageSize
            }
            this.$api.message.findByCondition(params,data).then(res=>{
                if(res.data){
                    this.list = res.data.list;
                    this.paging.total = res.data.total; 
                    this.paging.totalPages = res.data.pages; 
                }
            })
        },
        handleDelete(id){
            this.deletedialogVisible = true;
            this.deleteMessageId = id;
        },
        handleDeleteConfirm(){
            const params = {
                id:this.deleteMessageId
            }
            this.$api.message.deleteMessage(params).then(res=>{
                if(res.data){
                    this.$message.success(this.$t('WDNPC_WEB_OPERATION_SUCCEEDED',{text:'操作成功'}));
                    this.getData();
                }
                this.deletedialogVisible = false;
            })
        },
        // 全部已读
        readAllMessage(){
            this.$api.message.readAllMessage().then(res=>{
                if(res.data){
                    this.$message.success(this.$t('WDNPC_WEB_ALL_MESSAGES_HAVE_BEEN_READ',{text:'全部消息已读'}));
                    this.$emit('updateMessageStatus',false);
                    this.$store.commit('unreadMessageState',false)
                    this.getData();
                }
            })
        }
    },
    created(){
        this.getData();
    }
}
</script>
<style lang="stylus" scoped>
@import '../css/message.styl'
</style>

