<template>
    <div class="invoiceForm">
        <p class="tipTxt">
            <svg-icon icon-class="tips"></svg-icon>
            发票将发送至您的电子邮箱，请保证邮箱填写正确
        </p>
        <div class="invoices-item invoiceTitleType mb-24">
            <span class="itemSpan">抬头类型：</span>
            <div class="itemSpanType">
                <span v-if="invoiceType == 1" :class="invoiceTitleType == 2 ? 'active' : ''" @click="handleType(2)">个人</span>
                <span :class="invoiceTitleType == 1 ? 'active' : ''" @click="handleType(1)">单位</span>
            </div>
        </div>
        <div class="invoices-item invoiceContent mb-24">
            <span class="itemSpan">发票内容：</span>
            <span>{{ applyInfo.invoiceContent }}</span>
        </div>
        <div class="invoices-item mb-24">
            <span class="itemSpan">发票金额：</span>
            <span class="invoiceAmount">￥{{ applyInfo.invoiceAmount }}</span>
        </div>

        <el-form :model="formData.filter" :rules="rules" ref="ruleForm" class="form">
            <!-- 单位 -->
            <div v-if="invoiceTitleType == 1">
                <el-form-item label="单位名称：" prop="invoiceTitle">
                    <el-input v-model="formData.filter.invoiceTitle" maxlength="25"></el-input>
                </el-form-item>
                <el-form-item label="纳税人识别号：" prop="taxNumber">
                    <el-input v-model="formData.filter.taxNumber" maxlength="25"></el-input>
                </el-form-item>
                <el-form-item label="电子邮箱：" prop="recipientEmail">
                    <el-input v-model="formData.filter.recipientEmail" maxlength="25"></el-input>
                </el-form-item>
                <el-form-item label="开户银行（选填）：">
                    <el-input v-model="formData.filter.bankName" maxlength="25"></el-input>
                </el-form-item>
                <el-form-item label="银行账号（选填）：">
                    <el-input v-model="formData.filter.bankAccount" maxlength="25"></el-input>
                </el-form-item>
                <el-form-item label="注册电话（选填）：">
                    <el-input v-model="formData.filter.registPhone" maxlength="15"></el-input>
                </el-form-item>
                <el-form-item label="注册地址（选填）：">
                    <el-input v-model="formData.filter.registAdress" maxlength="50"></el-input>
                </el-form-item>
            </div>
            <!-- 个人 -->
            <div v-if="invoiceTitleType == 2">
                <el-form-item label="姓名：" prop="invoiceTitle">
                    <el-input v-model="formData.filter.invoiceTitle" maxlength="25"></el-input>
                </el-form-item>
                <el-form-item label="身份证号：" prop="idCard">
                    <el-input v-model="formData.filter.idCard" maxlength="25"></el-input>
                </el-form-item>
                <el-form-item label="电子邮箱：" prop="recipientEmail">
                    <el-input v-model="formData.filter.recipientEmail" maxlength="25"></el-input>
                </el-form-item>
            </div>

            <el-form-item>
                <div class="submitbtnBox">
                    <el-button @click="submitForm('ruleForm')" class="submitData">申请开票</el-button>
                </div>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import { emailValidator, idCardRegex } from '@/utils/validator'
export default {
    name: 'ruleForm',
    props: {
        invoiceType: {
            //发票类型 1普通发票 2增值税专用发票
            type: [String, Number],
            default: '1',
        },
        invoiceTitleType: {
            type: [String, Number],
            default: '2', // 1单位 2个人
        },
        applyInfo: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            formData: {
                ref: 'ruleForm',
                labelWidth: 'auto',
                filter: {
                    invoiceType: '1',
                    invoiceTitleType: '2',
                    invoiceTitle: '',
                    taxNumber: '',
                    bankName: '',
                    bankAccount: '',
                    registPhone: '',
                    registAdress: '',
                    recipientName: '',
                    recipientPhone: '',
                    recipientAddress: '',
                    recipientEmail: '',
                    orderId: 0,
                    idCard: '',
                },
                btnConfig: {},
            },
            rules: {
                idCard: [
                    { required: true, message: '请输入身份证号', trigger: 'blur' },
                    { required: true, validator: idCardRegex, message: '请输入正确的身份证号', trigger: 'blur' },
                ],
                invoiceTitle: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
                taxNumber: [{ required: true, message: '请输入纳税人识别号', trigger: 'blur' }],
                registAdress: [{ required: true, message: '请输入注册地址', trigger: 'blur' }],
                recipientAddress: [{ required: true, message: '请输入收件地址', trigger: 'blur' }],
                recipientEmail: [
                    { required: true, message: '请输入邮箱地址', trigger: 'blur' },
                    {
                        validator: emailValidator,
                        message: '请输入正确的邮箱地址',
                        trigger: 'blur',
                    },
                ],
            },
        }
    },
    watch: {
        invoiceType(val) {
            let str = ''
            if (val == 1) str = '请输入姓名'
            if (val == 2) str = '请输入单位名称'
            this.rules.invoiceTitle[0].message = str
        },
    },
    methods: {
        // 切换类型
        handleType(type) {
            console.log('🚀 ~ file: form.vue ~ line 137 ~ handleType ~ type', type)
            this.$emit('update:invoiceTitleType', type)
            let str = ''
            if (type == 1) str = '请输入单位名称'
            if (type == 2) str = '请输入姓名'
            this.rules.invoiceTitle[0].message = str
            this.$refs.ruleForm.resetFields()
        },
        submitForm(formName) {
            const that = this
            this.$refs[formName].validate(valid => {
                if (valid) {
                    that.$emit('submitData', that.formData.filter)
                } else {
                    return false
                }
            })
        },
    },
}
</script>
<style scoped lang="stylus">
@import '../css/invoice.styl'
</style>

<style lang="less" scoped>
.invoiceForm {
    .invoices-item {
        &.mb-24 {
            margin-bottom: 24px;
        }
        .itemSpanType {
            display: flex;
            justify-content: space-between;
            width: 366px;
            // flex-grow: 1;
            span.active {
                color: rgba(49, 111, 255, 1);
                border: 1px solid rgba(49, 111, 255, 1);
                &::after {
                    bottom: 0;
                    position: absolute;
                    content: '';
                    width: 0;
                    height: 0;
                    border-color: #fff #316fff #316fff #fff;
                    border-width: 10px 10px 10px 10px;
                    border-style: solid;
                    border-radius: 0 0 3px 0;
                    right: 0;
                }
                &::before {
                    position: absolute;
                    width: 15px;
                    height: 12px;
                    bottom: 0;
                    content: '';
                    z-index: 3;
                    right: -1px;
                    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAABHElEQVQ4T62TIUtEURCFv4MsBhVWg0VQsJssq0mw2ixWg6AYtvgDtAlWy7atglmLIFaDBrVqtSlYxOCRC/Pk+dz3ln28iffe+e6ZmTOi4VDDPGoDba8D35Ku86JqAW3vA6eAgSVJTxl0ZKDtFeAGaAFfwIKk11pA27PAHTAXgF1JvVol2x4DroC1APQlbReH+qdk2+nnGUkPxYe2T4CDOL8HViV9lgJtTwCpF5NAH9jLEmxvAueR/AYsS3oZZLlfhbbbwDMwnVORQOPALTCVbAJsSLos82+x5E4oyZqe1HwA8wE4lHRUtQz/bBOTPMs1P8u/CHXJe6Ux0Icx0ePcEN6BRUlJcWVUGtv2DrAFdCU9DoOl+5E3ZRi0ceAPcbpRESP3EG8AAAAASUVORK5CYII=')
                        no-repeat center;
                    background-size: cover;
                }
            }
            span {
                width: 175px;
                height: 40px;
                line-height: 40px;
                border: 1px solid rgba(221, 221, 221, 1);
                color: rgba(153, 153, 153, 1);
                font-size: 16px;
                border-radius: 4px;
                text-align: center;
                display: inline-block;
                position: relative;
                cursor: pointer;
            }
        }
        label,
        span.itemSpan {
            width: 146px;
            color: #333;
            font-size: 16px;
            display: inline-block;
            text-align: right;
        }
        span {
            &.invoiceAmount {
                font-weight: 700;
                font-size: 22px;
                color: #e82424 !important;
            }
        }
        span:nth-child(2) {
            color: #666666;
            font-size: 16px;
        }
        &.invoiceContent {
            align-items: flex-start;
            display: flex;
            span:nth-child(2) {
                display: inlne-block;
                width: 366px;
            }
        }
    }
}
</style>
