<template>
    <div class="offlineOrder">
      <div class="item" v-for="item in list" :key="item.id">
        <img v-if="item.status === 1" class="pay-icon" src="../../../../assets/images/order/paysuccess.png" alt="">
        <img v-if="item.status === 2" class="pay-icon" src="../../../../assets/images/order/closepay.png" alt="">
        <div class="top">
            <p class="order">
              <span class="label">订单编号：</span>
              <span class="content">{{item.orderNo}}</span>
            </p>
            <p class="time" v-if="item.tradeTime">
              <span class="label">下单时间：</span>
              <span class="content">{{item.tradeTime}}</span>
            </p>
            <p class="fangshi">
              <span class="label">支付方式：</span>
              <span class="content">线下订单</span>
            </p>
        </div>
        <div class="bottom">
            <div class="left">
                <img src="../../../../assets/images/order/offlineOrder-pic.png" alt="">
            </div>
            <div class="right">
                <p class="name">{{item.classTypeStr}}</p>
                <div class="right-bottom">
                  <p class="money">
                    <span class="label">金额：</span>
                    <span class="content">¥{{item.paymentAmount}}</span>
                  </p>
                  <p class="button" v-if="item.status !== 2"  @click="getFileStream(item)">电子凭证</p>
                </div>
            </div>
        </div>
      </div>
      <el-pagination
          v-if="pageInfo.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageInfo.pageNum"
          :page-sizes="[10, 20, 30]"
          :page-size="pageInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageInfo.total">
      </el-pagination>
      <el-dialog
        title="电子凭证"
        :visible.sync="dialogVisible"
        v-if="dialogVisible"
        width="1000px"
        top="5vh"
        :before-close="handleClose">
        <iframe id="preview" type="application/x-google-chrome-pdf" :src="PreviewURL" width="100%" height="600px" allowfullscreen frameborder="0" scrolling="no"></iframe>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose">取 消</el-button>
          <el-button type="primary" >
              <a :href="downloadUrl" download="电子凭证" target="_self">下载</a>
          </el-button>
        </span>
      </el-dialog>
    </div>
</template>

<script>
import {htmlPdf} from '../../libs/offlineOrderHtml';
import { exportMethod } from '../../../../utils/pdf';
import baseUrl from '@/config/url';
export default {
  name:'offlineOrder',
  data(){
      return {
          PreviewURL:'',
          downloadUrl:'',
          dialogVisible:false,
          list:[],
          pageInfo: {
              total: 0,
              size: 10,
              pageNum: 1,
              pageSize: 10
          },
      }
  },
  created(){
    this.getData()
  },
  methods:{
    handleClose(){
      this.dialogVisible = false;
    },
    handleSizeChange(val) {
        this.pageInfo.pageNum = 1
        this.pageInfo.pageSize = val
        this.getData()
    },
    handleCurrentChange(pageNum) {
        this.pageInfo.pageNum = pageNum
        this.getData()
    },
    
    getData(){
        const data = {
          statusList:[1,2]
        };
        const params = {
            pageNum:this.pageInfo.pageNum,
            pageSize:this.pageInfo.pageSize,
        }
        this.$api.certificate.getOfflineOrder({params,data}).then(res => {
            console.log('res',res);
            if(res.data){
                this.pageInfo.total = res.data.total;
                this.list = res.data.list;
            }
        })
    },
    getFileStream(item){
        this.downloadUrl = item.certificateUrl;
        const queryParams = {
          filename:item.certificateUrl
        }
        let myObj = {
            method: 'get',
            url: `${baseUrl.API}/saas/v1/offlineOrder/getFileStream`,
            fileName: '电子凭证.pdf',
            queryParams
        }
        exportMethod(myObj).then(res => {
          let blob = new Blob([res.data], {type: 'application/pdf'});
          this.dialogVisible = true;
          this.PreviewURL =  window.URL.createObjectURL(blob);
          console.log(this.PreviewURL,'url==');

        })
        
    },
    htmlToPdf(item){
      const html = htmlPdf(item);
      console.log(html,'html');
      const data = {
        html:html,
        orderNo:item.orderNo
      }
      this.$api.certificate.htmlToPdf({data}).then(res=>{
          console.log(res);
          if(res.data){
              this.getFileStream(res.data.downloadUrl);
              // this.PreviewURL= res.data.viewpdfUrl;
              this.downloadUrl = res.data.downloadUrl;
              this.dialogVisible = true;
          }
      })
    }
    
  }
}
</script>

<style lang="stylus" src="../../css/offlineOrder.styl" scoped>
 
</style>