// 查看证书
// import {downloadIamge} from './downloadImage';
export  default {
    data(){
        return{
            // 证书是否可点击：
            isClickCertificate:false,
            previewModalVisbile:false,
            // 实名弹窗
            authGuideModalVisbile:false,
            previewUrl:'',
            previewInfoImg:{
                name:'',
                userName:'',
            },
        }
    },
    methods:{
          // 是否需要实名认证 
        // 训练营里面，我的证书只传参数businessId, 我的证书列表里面，点击证书详情，只需要传id
        // 判断 isAuthentication  userAuthenStatus  ,
        // 1.isAuthentication 为0代表证书不需要实名 直接展示证书
        // 2.isAuthentication 为1代表证书 需要实名，
        // 判断 userAuthenStatus  是0  代表用户未实名，此时需要让用户去实名
        // userAuthenStatus是1代表用户已经实名 直接展示证书
        checkCertificate(key,value,btn,train,index){
            this.$api.certificate.getCertificateWithCheck(key,value).then(res=>{
                if(res.data){
                    this.isClickCertificate = true;
                    this.previewInfoImg.name = res.data.businessName;
                    this.previewInfoImg.userName = res.data.userName;
                    this.previewInfoImg.phone = res.data.phone;
                    this.previewUrl = res.data.url;
                    if(res.data.isAuthentication == 0 || (res.data.isAuthentication==1 && res.data.userAuthenStatus ==1)){ //不需要实名或者需要实名并且已实名
                        if(!res.data.url){
                            this.dialogVisible = true //需要用户去完善资料
                            return
                        }
                        if(btn=='btn'){
                            this.previewModalVisbile = true;
                            if(!train){
                                this.trainList[index].url = res.data.url;
                                this.$forceUpdate();
                                this.getData();
                            }
                        }
                        this.authGuideModalVisbile = false;
                    }else{
                        this.previewModalVisbile = false;
                        if(btn=='btn'){
                            this.authGuideModalVisbile = true;
                        }
                    }
                    // if(res.data.url && train =='train'){
                    //     this.isClickCertificate = true;
                    // }else{
                    //     if(train =='train' && btn=='btn'&& !res.data.url){
                    //         this.$message({
                    //             type:'warning',
                    //             message:'你还没有获得证书，请继续努力！',
                    //             duration:2000
                    //         })
                    //     }
                    // }
                }else{
                    this.isClickCertificate = false
                    if(train=="train" &&  btn=='btn'){
                        this.$message({
                            type:'warning',
                            message:'你还没有获得证书，请继续努力！',
                            duration:2000
                        })
                        return 
                    }
                }   
               
            })
        },
        handleCloseAuth(){
            this.authGuideModalVisbile = false;
        },
        handleClosePreview(){
            this.previewModalVisbile = false;
        },
        downLoadCer(key,value,userName,name,index){
            this.$api.certificate.getCertificateWithCheck(key,value).then(res=>{
                if(res.data){
                    if(res.data.isAuthentication == 0 || (res.data.isAuthentication==1 && res.data.userAuthenStatus ==1)){
                        if(!res.data.url){
                            this.dialogVisible = true //需要用户去完善资料
                            return
                        }
                        if(index && res.data.userAuthenStatus ==1){
                            this.trainList[index].url = res.data.url;
                            this.$forceUpdate();
                            this.getData();
                        }
                        let x=new XMLHttpRequest();
                        x.open("GET", res.data.url, true);
                        x.setRequestHeader('Cache-Control', 'no-cache');
                        x.responseType = 'blob';
                        x.onload=function(){
                            let url = window.URL.createObjectURL(x.response)
                            let a = document.createElement('a');
                            a.href = url;
                            a.download = userName+'-'+ name +'.jpg';
                            a.click()
                        }
                        x.send();
                    }else{
                        this.authGuideModalVisbile = true;
                    }
                }
            })
        }
    },
    created() {
    },
}