<!-- 
 * @description: 我的订单
 * @fileName: my-order.vue 
 * @author:yr 
 * @date: 2021-10-11 
!-->
<template>
    <div class="my-order">
        <div class="tab1" v-if="orderGoodsTypeList.length">
            <span
                :class="{ active: orderActive === item.value }"
                v-for="item in orderGoodsTypeList"
                :key="item.value"
                @click="handleOrderTab(item)"
                >{{ item.label }}</span
            >
        </div>
        <div class="tab" v-if="orderActive === 1">
            <span :class="{ active: currentTab === -1 }" @click="handleClickTab(-1)">全部订单</span>
            <span :class="{ active: currentTab === 0 }" @click="handleClickTab(0)">待付款</span>
            <span :class="{ active: currentTab === 2 }" @click="handleClickTab(2)">已完成</span>
            <span :class="{ active: currentTab === 9 }" @click="handleClickTab(9)">已关闭</span>
        </div>
        <!-- 培训订单 -->
        <template v-if="orderActive === 1">
            <invoices-form
                v-if="hasOrder && applyInvoice"
                @myOrderList="myOrderList"
                :applyInfo="applyInfo"
                @applyInvoiceShow="applyInvoiceShow"
            ></invoices-form>
            <template v-if="!bEvaluate">
                <ul class="orderul" v-if="hasOrder && !applyInvoice">
                    <li class="orderli" v-for="(order, index) in orderlist" :key="index">
                        <!-- is_show_invoice is_show_evaluate 双认证定制的-->
                        <template v-if="is_show_invoice && is_show_evaluate">
                            <!-- 0 未支付；1 支付中；2 已支付；8 已关闭；9 已取消 -->
                            <img v-if="order.status === 2" class="paysuccess" src="../../../assets/images/order/paysuccess.png" alt="" />
                            <img
                                v-if="order.status === 8 || order.status === 9"
                                class="paysuccess"
                                src="../../../assets/images/order/closepay.png"
                                alt=""
                            />
                            <img v-if="order.status === 0" class="paysuccess" src="../../../assets/images/order/waitpay.png" alt="" />
                        </template>
                        <div class="ordertop">
                            <span class="number">订单编号：{{ order.orderNo }}</span>
                            <span class="ordertime">下单时间：{{ order.orderTime }}</span>
                            <span v-if="order.payWay" class="ordertime">支付方式：{{ order.payWay }}</span>
                        </div>
                        <div class="orderCurseInfo" v-for="(item, ind) in order.goodsList" :key="ind">
                            <!-- 工信原图片链接字段：trainPicUrl -->
                            <img class="orderCourseImg" :src="item.picUrl" alt="" @click="goDetails(item.id, order.orderType)" />
                            <div class="orderCurseRight">
                                <h3 @click="goDetails(item.id, order.orderType)">{{ item.name }}</h3>
                                <div class="bottom-order">
                                    <p>
                                        <span class="price_key">实付金额：</span>
                                        <span class="price_val">￥{{ order.realPay }}</span>
                                    </p>
                                    <div class="bottom-order-right" v-if="is_show_invoice && is_show_evaluate">
                                        <div class="btn_pay">
                                            <p class="daoji" v-if="order.status == 0">
                                                请在<span>{{ order.left_time }}</span
                                                >内完成支付
                                            </p>
                                            <button
                                                @click="handleApply(order.id, index, order.realPay, item.name, order.orderTime)"
                                                class="applyBtn itemBtn"
                                                v-if="order.invoiceStatus == 1 && order.status == 2 && order.realPay != 0 && isInvoice"
                                            >
                                                申请开票
                                            </button>
                                            <button
                                                @click="handlePreview(order.invoiceId)"
                                                class="previewBtn itemBtn"
                                                v-else-if="order.invoiceStatus == 2 && order.status == 2 && isInvoice"
                                            >
                                                查看开票
                                            </button>
                                            <button
                                                @click="goPayment(order.goodsList[0].id, order.orderType, order.orderNo)"
                                                class="payBtn itemBtn"
                                                v-if="order.status == 0"
                                            >
                                                马上支付
                                            </button>
                                        </div>
                                        <!-- evaluationTag 0：隐藏 1：查看评价 2：评价 3：禁用  -->
                                        <template v-if="order.evaluationTag > 0 && isEvaluateBtn">
                                            <div class="evaluate_btn">
                                                <button
                                                    @click="onEvaluate(order)"
                                                    class="btn_evalute"
                                                    :class="{
                                                        c1: order.evaluationTag == 1,
                                                        c2: order.evaluationTag == 2,
                                                        c3: order.evaluationTag == 3,
                                                    }"
                                                >
                                                    {{ onStatus(order.evaluationTag) }}
                                                </button>
                                            </div>
                                        </template>
                                    </div>
                                    <div class="bottom-order-status" v-else>
                                        <span class="wait" v-if="order.status === 0">待付款</span>
                                        <span class="ok" v-else-if="order.status === 2">已完成付款</span>
                                        <span class="ok" v-else-if="order.status === 8 || order.status === 9">已关闭</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="pagenation-wraper" v-show="orderlist.length && hasOrder && !applyInvoice">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="pageInfo.pageNum"
                        :page-sizes="[10, 20, 30]"
                        :page-size="pageInfo.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="pageInfo.total"
                    >
                    </el-pagination>
                </div>

                <div class="nodata-tip" v-if="!hasOrder">
                    <img src="./../../../assets/images/common/nodata.png" alt="" />
                    <p>还没有订单，赶快去购课吧～</p>
                </div>
            </template>
            <template v-if="bEvaluate">
                <evaluate @back="onBack" :s-look="bShow" :order-no="orderNo" />
            </template>
        </template>
        <!-- 线下订单 -->
        <template v-if="orderActive === 0">
            <offlineOrder></offlineOrder>
        </template>
        <!-- 发票详情 -->
        <el-dialog title="" :visible.sync="invoiceOuterVisible" class="invoiceDialog" width="480px" :before-close="handleClose">
            <div class="info-list">
                <p class="info-title">
                    查看开票申请<span :class="formInfo.invoiceStatus == 2 ? 'green' : ''">{{ formInfo.invoicesTxt }}</span>
                </p>
                <div class="info-item">
                    <span>发票类型：</span>
                    <span>{{ formInfo.invoiceType == 1 ? '普通发票' : '增值税专用发票' }}</span>
                </div>

                <div class="info-item">
                    <span>抬头类型：</span>
                    <span>{{ formInfo.invoiceTitleType == 1 ? '单位' : '个人' }}</span>
                </div>
                <div class="info-item">
                    <span>发票内容：</span>
                    <span>{{ formInfo.invoiceContent }}</span>
                </div>
                <div class="info-item">
                    <span>发票金额：</span>
                    <span class="money-num">￥{{ formInfo.invoiceAmount }}</span>
                </div>
                <div v-if="formInfo.invoiceTitleType == 1">
                    <div class="info-item">
                        <span>单位名称：</span>
                        <span>{{ formInfo.invoiceTitle }}</span>
                    </div>
                    <div class="info-item">
                        <span>纳税人识别号：</span>
                        <span>{{ formInfo.taxNumber || '' }} {{ formInfo.expressCompany || '' }}</span>
                    </div>
                    <div class="info-item">
                        <span>电子邮箱：</span>
                        <span>{{ formInfo.recipientEmail }}</span>
                    </div>
                    <div class="info-item">
                        <span>开户银行（选填）：</span>
                        <span :class="formInfo.bankName ? '' : 'grey'">{{ formInfo.bankName || '未填写' }}</span>
                    </div>
                    <div class="info-item">
                        <span>银行账号（选填）：</span>
                        <span :class="formInfo.bankAccount ? '' : 'grey'">{{ formInfo.bankAccount || '未填写' }}</span>
                    </div>
                    <div class="info-item">
                        <span>注册电话（选填）：</span>
                        <span :class="formInfo.registPhone ? '' : 'grey'">{{ formInfo.registPhone || '未填写' }}</span>
                    </div>
                    <div class="info-item">
                        <span>注册地址（选填）：</span>
                        <span :class="formInfo.registAdress ? '' : 'grey'">{{ formInfo.registAdress || '未填写' }}</span>
                    </div>
                </div>
                <div v-if="formInfo.invoiceTitleType == 2">
                    <div class="info-item">
                        <span>姓名：</span>
                        <span>{{ formInfo.invoiceTitle }}</span>
                    </div>
                    <div class="info-item">
                        <span>身份证号：</span>
                        <span>{{ formInfo.idCard }}</span>
                    </div>
                    <div class="info-item">
                        <span>电子邮箱：</span>
                        <span>{{ formInfo.recipientEmail }}</span>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import invoicesForm from './invoicesForm.vue'
import evaluate from './components/evaluate'
import offlineOrder from './components/offlineOrder.vue'
export default {
    name: 'myOrder',
    data() {
        return {
            isEvaluateBtn: true, // 是否显示评价按钮 单独控制
            isInvoice: true, // 是否显示开票按钮 单独控制
            orderTypeList: [],
            orderGoodsTypeList: [],
            orderActive: 1,
            // 默认展示-----默认为true**********************************************************************
            is_show_invoice: true, //开发票
            is_show_evaluate: true, //评价
            is_order_isolate_by_branch: true, //订单列表是否机构隔离
            currentTab: -1,
            hasOrder: true,
            applyInvoice: false,
            orderlist: [],
            applyInfo: {
                invoiceAmount: '',
                invoiceContent: '',
            },
            bEvaluate: false,
            bShow: false,
            orderNo: '',
            invoiceOuterVisible: false,
            formInfo: {
                invoiceStatus: 1,
                invoicesTxt: '',
            },
            timer: null,
            pageInfo: {
                total: 0,
                size: 10,
                pageNum: 1,
                pageSize: 10,
            },
        }
    },
    components: {
        invoicesForm,
        evaluate,
        offlineOrder,
    },
    methods: {
        // 订单tab点击切换
        handleOrderTab(item) {
            this.orderActive = item.value
            if (item.value === 1) {
                this.currentTab = -1
                this.myOrderList()
            }
        },
        initConfig() {
            let configArr = localStorage.getItem('configurationArr') && JSON.parse(localStorage.getItem('configurationArr'))
            configArr.forEach(item => {
                switch (item.key) {
                    case 'is_show_invoice':
                        this.is_show_invoice = item.value === 'false' ? false : true
                        break
                    case 'is_show_evaluate':
                        this.is_show_evaluate = item.value === 'false' ? false : true
                        break
                    case 'is_order_isolate_by_branch':
                        this.is_order_isolate_by_branch = item.value === 'false' ? false : true
                        break
                    case 'order_list_pingjia':
                        this.isEvaluateBtn = item.value && item.value === 'true' ? true : false
                        break
                    case 'order_list_fapiao':
                        this.isInvoice = item.value && item.value === 'true' ? true : false
                        break
                    case 'is_show_orderlist_Ordertype':
                        this.orderTypeList = item.value ? JSON.parse(item.value) : []
                        if (this.orderTypeList && this.orderTypeList.indexOf('培训订单') != -1) {
                            this.orderGoodsTypeList.push({
                                value: 1,
                                label: '培训订单',
                            })
                        }
                        if (this.orderTypeList && this.orderTypeList.includes('线下订单')) {
                            this.orderGoodsTypeList.push({
                                value: 0,
                                label: '线下订单',
                            })
                        }
                        if (this.orderGoodsTypeList.length == 1 && this.orderTypeList.indexOf('培训订单') != -1) {
                            this.orderGoodsTypeList = []
                        }
                        if (this.orderGoodsTypeList.length == 1 && this.orderTypeList.includes('线下订单')) {
                            this.tabActive = 0
                            this.orderGoodsTypeList = []
                        }
                        break
                    default:
                        break
                }
            })
        },
        handleClickTab(val) {
            this.currentTab = val
            this.myOrderList()
        },
        async myOrderList() {
            const params = {
                pageNum: this.pageInfo.pageNum,
                pageSize: this.pageInfo.pageSize,
                orderStatus: this.currentTab === -1 ? null : this.currentTab,
            }
            if (!this.is_order_isolate_by_branch) {
                //订单机构隔离
                params.goodsKinds = 1 //培训商品
                params.toC = 1
            }
            // this.$api.certificate.myOrderList(params).then(({data}) =>{
            //     this.orderlist = data.list
            //     this.pageInfo.total = data.total
            //     this.hasOrder = this.orderlist.length > 0;
            //     this.countDown();
            // })
            let res = await this.$api.certificate.myOrderList(params)
            if (res.success) {
                this.orderlist = res.data.list || []
                this.pageInfo.total = res.data.total
                this.hasOrder = this.orderlist.length > 0
                this.countDown()
            }
        },
        applyInvoiceShow() {
            this.applyInvoice = false
        },
        countDown() {
            var that = this
            that.timer = setInterval(function () {
                var orders = that.orderlist
                for (var i = 0; i < orders.length; i++) {
                    var status = orders[i].status
                    if (status == 0) {
                        var orderTime = orders[i].orderTime
                        //计算剩余时间差值
                        var leftTime = new Date(orderTime).getTime() + 30 * 60 * 1000 - new Date().getTime()
                        if (leftTime > 0) {
                            //计算剩余的分钟
                            var minutes = that.formatNumber(parseInt((leftTime / 1000 / 60) % 60, 10))
                            //计算剩余的秒数
                            var seconds = that.formatNumber(parseInt((leftTime / 1000) % 60, 10))
                            var left_time = minutes + ':' + seconds
                            orders[i].left_time = left_time
                        } else {
                            //改变超时未支付的订单状态
                            that.orderlist[i].status = 8
                        }
                    }
                    if (orders[i].invoiceStatus == 1) {
                        var nowDate = new Date().getTime()
                        var starDate = new Date(orders[i].orderTime).getTime()
                        var reTime = 365 * 24 * 60 * 60 * 1000
                        if (starDate + reTime - nowDate < 0) {
                            //过期
                            that.orderlist[i].invoiceStatus = 3
                        }
                    }
                }
                that.orderlist = orders
                that.$forceUpdate()
            }, 1000)
        },
        formatNumber(num) {
            return num >= 10 ? num : '0' + num
        },
        onBack() {
            this.myOrderList()
            this.bEvaluate = false
        },
        // 商品详情
        goDetails(id, orderType) {
            //大平台商品跳转
            /**
             * goodsKind 2 分销商品 1 普通商品
             */
            this.$router.push({
                path: '/courseGoodsDetail',
                query: {
                    goodsId: id,
                    orderType,
                    goodsKind: orderType == 4 ? 2 : 1,
                },
            })
        },
        // 申请
        handleApply(id, index, realPay, name, time) {
            if (this.orderlist[index].invoiceStatus == 1) {
                var nowDate = new Date().getTime()
                var starDate = new Date(time).getTime()
                var reTime = 90 * 24 * 60 * 60 * 1000
                if (starDate + reTime - nowDate < 0) {
                    //过期
                    this.$message({ type: 'warning',message: '此订单申请发票已过期' })
                    return
                }
            }
            this.applyInvoice = true
            this.applyInfo.invoiceAmount = realPay
            this.applyInfo.invoiceContent = name
            this.applyInfo.id = id
        },
        // 查看
        handlePreview(id) {
            this.invoiceOuterVisible = true
            this.$api.invoice.getDataDetails(id).then(res => {
                if (res.data) {
                    this.formInfo = { ...res.data }
                    if (this.formInfo.invoiceStatus == 1) {
                        this.formInfo.invoicesTxt = '（审核中）'
                    } else if (this.formInfo.invoiceStatus == 2) {
                        this.formInfo.invoicesTxt = '（已开票）'
                    }
                }
            })
        },
        // 去付款
        goPayment(id, orderType, orderNo) {
            //orderType  4渠道分销订单
            // console.log(orderType,'===================')
            if (!localStorage.getItem('token')) {
                this.$router.push({ path: '/login', query: { name: 'personalCenter' } })
            } else {
                this.$router.push({
                    path: '/orderPage',
                    query: {
                        id: id,
                        orderType,
                        orderNo,
                        goodsKind: orderType == 4 ? 2 : 1,
                    },
                })
            }
        },
        // 评价
        onEvaluate(item) {
            const { evaluationTag, orderNo } = item
            // 0：隐藏 1：查看评价 2：评价 3：禁用
            console.log(evaluationTag)
            if (evaluationTag === 3) {
                this.$message.warning('培训任务完课后，才可评价呦~')
                return
            }
            this.bEvaluate = true
            if (evaluationTag === 2) {
                this.bShow = true
            } else {
                this.bShow = false
            }
            this.orderNo = orderNo
        },
        onStatus(num) {
            let objNum = {
                1: '查看评价',
                2: '立即评价',
                3: '立即评价',
            }
            num = parseInt(Math.ceil(num))
            return objNum[num]
        },
        handleClose() {
            this.invoiceOuterVisible = false
        },
        handleSizeChange(val) {
            this.pageInfo.pageNum = 1
            this.pageInfo.pageSize = val
            this.myOrderList()
        },
        handleCurrentChange(pageNum) {
            this.pageInfo.pageNum = pageNum
            this.myOrderList()
        },
    },
    created() {
        this.initConfig()
        this.myOrderList()
    },
    destroyed() {
        clearInterval(this.timer)
    },
}
</script>
<style lang="stylus" scoped>
@import '../css/order.styl'
.pagenation-wraper
  margin 48px auto;
  display: flex
  justify-content: center
  /deep/.el-pagination
    display inline-block
</style>

<style lang="less" scoped>
.grey {
    color: #999 !important;
}
</style>
