<template>
    <div class="invoiceForm">
        <el-button type="text" class="backBtn" @click="goBackBtn()">
            <svg-icon icon-class="back"></svg-icon>
            返回
        </el-button>
        <div class="formTabs">
            <el-tabs v-model="activeName" @tab-click="handleClick()">
                <el-tab-pane label="普通发票" name="first">
                    <rule-form
                        ref="ruleForm1"
                        :invoiceType="form.invoiceType"
                        :invoiceTitleType.sync="form.invoiceTitleType"
                        :applyInfo="applyInfo"
                        @submitData="submitData"
                    />
                </el-tab-pane>
                <el-tab-pane label="增值税专用发票" name="second">
                    <rule-form
                        ref="ruleForm2"
                        :invoiceType="form.invoiceType"
                        :invoiceTitleType.sync="form.invoiceTitleType"
                        :applyInfo="applyInfo"
                        @submitData="submitData"
                    />
                </el-tab-pane>
            </el-tabs>
        </div>

        <el-dialog :visible.sync="dialogVisible" width="400px" class="modalTip" :append-to-body="appendToBody" :before-close="handleClose">
            <svg-icon icon-class="tips" class-name="icon-img"></svg-icon>
            <p class="title">{{ text1 }}</p>
            <p class="text">{{ text2 }}</p>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="handleConfirm">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import ruleForm from './form.vue'
export default {
    name: 'invoicesForm',
    props: {
        applyInfo: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            appendToBody: false,
            dialogVisible: false,
            activeName: 'first',
            text1: '提示',
            text2: '提交后发票信息将无法更改',
            form: {
                invoiceType: '1', //发票类型 1普通发票 2增值税专用发票
                invoiceTitleType: '2', //抬头类型 1单位 2个人
                invoiceTitle: '',
                taxNumber: '',
                bankName: '',
                bankAccount: '',
                registPhone: '',
                recipientName: '',
                recipientPhone: '',
                recipientAddress: '',
                recipientEmail: '',
                orderId: 0,
            },
            formData: {
                filter: {
                    invoiceType: '1',
                    invoiceTitleType: '2',
                    invoiceTitle: '',
                    taxNumber: '',
                    bankName: '',
                    bankAccount: '',
                    registPhone: '',
                    registAdress: '',
                    recipientName: '',
                    recipientPhone: '',
                    recipientAddress: '',
                    recipientEmail: '',
                    orderId: 0,
                },
            },
        }
    },
    components: {
        ruleForm,
    },
    created() {},
    methods: {
        handleConfirm() {
            const that = this
            if (this.text2 == '提交后发票信息将无法更改') {
                const params = this.formData.filter
                params.invoiceType = this.form.invoiceType
                params.invoiceTitleType = this.form.invoiceTitleType
                params.orderId = this.applyInfo.id
                this.$api.invoice.postData(params).then(res => {
                    if (res.code == 0) {
                        that.$message({
                            message: '申请成功',
                            type: 'success',
                            onClose: function () {
                                //    that.applyInfo = false;
                                that.dialogVisible = false
                                that.$emit('myOrderList')
                                that.$emit('applyInvoiceShow')
                            },
                        })
                    } else {
                        that.$message.error(res.message)
                    }
                })
            } else {
                this.$emit('applyInvoiceShow')
            }
        },
        goBackBtn() {
            this.text2 = '已填写信息将不被保存，是否返回'
            this.dialogVisible = true
        },
        handleClose() {
            this.dialogVisible = false
        },
        submitData(obj) {
            const that = this
            that.text2 = '提交后发票信息将无法更改'
            that.dialogVisible = true
            this.formData.filter = obj
        },
        handleClick() {
            if (this.activeName == 'second') {
                this.form.invoiceType = 2
                this.form.invoiceTitleType = 1
                this.$refs.ruleForm2.$refs.ruleForm.resetFields()
            } else {
                this.$refs.ruleForm1.$refs.ruleForm.resetFields()
                this.form.invoiceType = 1
                this.form.invoiceTitleType = 2
            }
        },
    },
}
</script>
<style scoped lang="stylus">
@import '../css/invoice.styl'
</style>

<style lang="less" scoped>
.formTabs {
    ::v-deep .el-tabs {
        .el-tabs__header {
            margin-top: 0;
        }
        .el-tabs__nav-wrap {
            border-bottom: 1px solid #ddd;
        }
        .el-tabs__item.is-active::before {
            bottom: 0 !important;
            height: 2px !important;
            width: 100% !important;
            top: inherit !important;
        }
    }
}
</style>
