<template>
  <div class="star_wrap">
    <template v-if="bTotal">
      <div class="thsize">
        <div class="title">
          综合评分
        </div>
        <div class="star_icon n_score">
          <el-rate
            v-model="nScore"
            score-template="{nScore}"
            disabled
            :icon-classes="[
              'icon-xingxing-kongxin',
              'icon-xingxing-shixin',
              'icon-xingxing-shixin',
            ]"
            void-icon-class="icon-xingxing-kongxin"
            disabled-void-icon-class="icon-xingxing-kongxin"
            void-color="#D8D8D8"
            disabled-void-color="#D8D8D8"
          >
          </el-rate>
        </div>

        <div class="scroe">{{ nScore }}分</div>
      </div>
    </template>
    <ul class="ul_wrap" :style="{ background: bTotal ? '#F8F9FA' : '#Fff' }">
      <li v-for="item in aStar" :key="item.evaluationType">
        <div class="title">
          {{ item.evaluationTypeName }}
        </div>
        <div class="star_icon">
          <el-rate
            v-model="item.score"
            @change="onChange(item)"
            class="iconfont"
            allow-half
            :icon-classes="[
              'icon-xingxing-kongxin',
              'icon-xingxing-shixin',
              'icon-xingxing-shixin',
            ]"
            void-icon-class="icon-xingxing-kongxin"
            disabled-void-icon-class="icon-xingxing-kongxin"
            :disabled="bDisable"
            void-color="#D8D8D8"
            disabled-void-color="#D8D8D8"
          >
          </el-rate>
        </div>
        <div class="scroe">
          {{ onScore(item.score) }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import "./font/iconfont.css";

export default {
  name: "star",
  props: {
    bDisable: {
      type: Boolean,
      default: false,
    },
    bTotal: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 2.5,
    },
    aStar: {
      type: Array,
      default: () => {
        return [
          { evaluationType: 0, score: 0, evaluationTypeName: "培训课程" },
          { evaluationType: 1, score: 0, evaluationTypeName: "院校环境" },
          { evaluationType: 2, score: 0, evaluationTypeName: "师资规模" },
          { evaluationType: 3, score: 0, evaluationTypeName: "服务态度" },
        ];
      },
    },
  },
  data() {
    return {
      nScore: 2.5,
      colors: ["#FFD118", "#FFD118", "#FFD118"],
    };
  },
  watch: {
    total(val) {
      this.nScore = val;
    },
  },
  methods: {
    onChange(item) {
      console.log(123123);
      this.$emit("change", item);
    },
    onScore(num) {
      let objNum = {
        0: null,
        1: "有点差",
        2: "一般",
        3: "还不错",
        4: "很满意",
        5: "非常满意",
      };
      num = parseInt(Math.ceil(num));
      return objNum[num];
    },
  },
};
</script>
<style lang="less" scoped>
.star_wrap {
  font-family: PingFangSC-Regular, PingFang SC;
  /deep/ .el-rate {
    height: auto;
  }
  .thsize {
    display: flex;
    align-items: center;
    margin-top: 24px;
    .title {
      font-size: 18px;
      font-weight: 500;
      color: #333333;
      line-height: 25px;
    }
    .scroe {
      color: #333;
      font-size: 16px;
    }
  }
  .title {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
  }
  .star_icon {
    margin: 0 12px;
    /deep/ .el-rate__decimal {
      font-size: 26px;
      font-family: "iconfont" !important;
      font-style: normal;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    /deep/ .el-rate__icon {
      font-size: 26px;
      font-family: "iconfont" !important;
      font-style: normal;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
  .n_score {
    /deep/ .el-rate__decimal {
      font-size: 32px;
      font-family: "iconfont" !important;
      font-style: normal;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    /deep/ .el-rate__icon {
      font-size: 32px;
      font-family: "iconfont" !important;
      font-style: normal;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
  .scroe {
    font-size: 12px;
    font-weight: 400;
    color: #666666;
    line-height: 17px;
  }
  .ul_wrap {
    width: 396px;
    margin: 16px 0 18px;
    padding: 6px 11px 6px 11px;
  }
  li {
    display: flex;
    align-items: center;
    font-weight: 400;
    padding: 6px 0;
  }
}
</style>
