<!-- 
 * @description: 我的证书
 * @fileName: my-certificate.vue 
 * @author:yr 
 * @date: 2021-10-11 
!-->
<template>
    <div class="my-certificate">
        <ul class="certiUl">
            <li class="certili" v-for="(item,index) in trainList" :key="index">
                <div class="item-left">
                    <img :src="item.url" :alt="$t('WDNPC_WEB_CERTIFICATE',{text:'证书'})" v-if="item.url !=null">
                    <img src="../../../assets/images/order/certificate-place.png" :alt="$t('WDNPC_WEB_CERTIFICATE',{text:'证书'})" v-else>
                </div>
                <div class="item-right">
                    <div class="item-right-top">
                        <p class="certificate-name">{{item.businessName}}</p>
                        <p class="certificate-issuedBy">{{item.issuedBy}}</p>
                    </div>
                    <div class="item-right-bottom">
                        <p class="certificate-time">
                            <span>{{$t('WDNPC_WEB_TIME_OF_CERTIFICATION',{text:'获证时间：'})}}</span>
                            <span>{{item.certifiedTime && item.certifiedTime.substring(0,10)}}</span>
                        </p>
                        <div class="certificate-button">
                            <el-button  type="primary" class="certificateButton" @click="checkCertificate('id',item.id,'btn','',index)">{{$t('WDNPC_WEB_VIEW_CERTIFICATE',{text:'查看证书'})}}</el-button>
                            <el-button  type="primary" plain  @click="downLoadCer('id',item.id,item.userName,item.businessName,index)">{{$t('WDNPC_WEB_DOWNLOAD',{text:'下载'})}}</el-button>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
        <div class="nodata-tip" v-if = "trainList.length == 0">
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/common/nodatacer.png" alt="">
            <p>{{$t('WDNPC_WEB_YOU_DONT_HAVE_A_CERTIFICATE_YET',{text:'你还没有证书哟'})}}～</p>
        </div>
        <el-dialog
            class="tip-dialog"
            title=""
            :visible.sync="dialogVisible"
            width="400px">
            <div>
                <svg-icon icon-class="tips" class-name="icon-img"></svg-icon>
                <p class="titles">{{$t('WDNPC_WEB_PROMPT',{text:'提示'})}}</p>
                <p class="text">{{$t('WDNPC_WEB_PLEASE_COMPLETE_THE_INFORMATION_ON_THE_PROFILE',{text:'请先到个人资料页中完善信息'})}}</p>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">{{$t('WDNPC_WEB_CANCEL',{text:'取 消'})}}</el-button>
                <el-button type="primary" @click="toPersonal">{{$t('WDNPC_WEB_PERFECT_DATA',{text:'完善资料'})}}</el-button>
            </span>
        </el-dialog>
        <auth-guide :ModalVisbileAuth="authGuideModalVisbile" @handleCloseAuth="handleCloseAuth" :wechatErInfo="previewInfoImg"></auth-guide>
        <preview-certificate :previewModalVisbile="previewModalVisbile" @handleClosePreview="handleClosePreview" :isDownLoad="isDownLoad" :previewUrl="previewUrl"></preview-certificate>
    </div>
</template>
<script>
import cercertificateIsShowStatus from './../../../utils/certificateMixin'
export default {
    name:'myCertificate',
    data(){
        return{
            trainList:[],
            isDownLoad:false,
            dialogVisible:false
        }
    },
    mixins:[cercertificateIsShowStatus],
    methods:{
        getData(){
            this.$api.certificate.getCertificateList().then(res=>{
                if(res.data){
                    this.trainList = res.data;
                }
            })
        },
        toPersonal(){
            if(this.$route.path === '/personalCenter' || this.$route.path === '/personalCenter_new'){
                this.$emit('changeTab','我的资料')
            }else {
                sessionStorage.setItem('userCenterCurrentTab','我的资料')
                this.$router.push('/personalCenter')
            }
        }
    },
    created(){
        this.getData();
    }
}
</script>
<style lang="stylus" scoped>
@import '../css/certificate.styl'
.tip-dialog {
    text-align: center;
    /deep/.el-dialog {
        border-radius: 10px;
        .el-dialog__header {
            padding 0
            .el-dialog__headerbtn .el-dialog__close {
                font-size 26px    
            }
        }
        .el-dialog__body {
            padding-top: 40px;
            padding-bottom: 30px;
            .icon-img {
                font-size 50px;
                margin: 0 auto;
            }
            .titles {
                font-size: 24px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #1B2257;
                line-height: 34px;
                margin 15px 0
            }
            .text {
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 24px;
                word-break: break-word;
            }
        }
        .el-dialog__footer {
            padding: 0 36px 30px;
            .dialog-footer {
                display: flex;
                justify-content: center;
                .el-button {
                    height: 40px;
                    text-align: center;
                    &.el-button--default {
                        span {
                            color: #666;
                        }
                    }
                }
            }
        }
    }
}
</style>

