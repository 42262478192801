import axios from 'axios';
export function exportMethod(data) {
    console.log(data,'data----')
    return axios({
        method: data.method,
        url: `${data.url}${data.params ? '?' + data.params : ''}`,
        responseType: 'blob',
        data:data.data,
        headers:{
            organ:localStorage.getItem('organ'),
            token:localStorage.getItem('token'),
            actor:localStorage.getItem('actor'),
            channel:localStorage.getItem("channel"),
            branch:localStorage.getItem("branch")
        },
        params:data.queryParams? data.queryParams:null
    })
}